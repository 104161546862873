import LazyLoad from 'lazyload/lazyload.min.js'

$(function(){
  let images = document.querySelectorAll(".ck-lazyload");
  new LazyLoad(images,{
     root: null,
     rootMargin: "10% 0px",
     threshold: 0
  });
});
